import React from "react"
import styled from "styled-components"

const Content = styled.section`
  position: relative;
  //padding: 16px;
`;

const Section = ({ id, children }) => {

    return (
        <>
            <Content id={id}>{children}</Content>
        </>
    )
}

export default Section
