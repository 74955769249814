import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Layout from '../components/layout';
import SEO from "../components/seo";
import Hero from '../components/hero';
import styled from "styled-components";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import Container from "../components/container";
import CssGrid from "../components/grid";
import {styles, Title3} from "../components/styles";
import Article from "../components/article";
import Table from "../components/tables";
import GoogleMap from '../components/map';

const TableSection = styled.section`
  width: 100%;
  background-color: ${styles.colorBlack};
  padding: 52px 0;
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContactList = styled.ul`
  display: flex;
  flex-direction: column;
`

const ContactItem = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 5px 0;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;

  a {
    color: ${styles.colorSecondary};
    text-decoration: none;
    padding-left: 5px;
  }
`

const Map = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 600px;
  height: 60vw;
  overflow: hidden;
`

const IndexPage = ({data}) => {

  const query = useStaticQuery(graphql`
    query myQuery {
      allWordpressPost(limit: 6) {
        nodes {
          categories {
            name
          }
          id
          date
          title
          slug
          excerpt
          featured_media {
            source_url
          }
        }
      }

      allWordpressPage(filter: { wordpress_id: { eq: 45 }}) {
        nodes {
          wordpress_id
          content
        }
      }

      allWordpressAcfPages(filter: {wordpress_id: {eq: 45}}) {
        nodes {
          acf {
            tabela1_tabela_glowna
            tabela1_tytul
            tabela1_tabela_regionalne
            tabela2_tabela_glowna
            tabela2_tytul
            tabela2_tabela_regionalne
            tabela3_tabela_glowna
            tabela3_tytul
            tabela3_tabela_regionalne
          }
        }
      }
    }
  `);

  //const scrollTo = useRef(null);

  return(
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Section>
        <Container>
          <SectionHeader padding="25px 0">Najnowsze wydarzenia</SectionHeader>
          <CssGrid sm={'2'} lg={'2'} size="200px" sizelg="23vw" padding="25px 0">
            {query.allWordpressPost.nodes.map(item => (
              <Article title={item.title} date={item.date} slug={item.slug} excerpt={item.excerpt} social={"facebook"} categories={item.categories.map(category => category.name)} photo={item.featured_media}/>
            ))}
          </CssGrid>
        </Container>
      </Section>
      <TableSection id={'terminarze'}>
        <Container>
          <SectionHeader firstColor="#fff" secondColor={styles.colorPrimary} padding="25px 0">Terminarze zawodów</SectionHeader>
          {query.allWordpressAcfPages.nodes.map(item => (
              <Table 
                title={item.acf.tabela1_tytul} 
                content={item.acf.tabela1_tabela_glowna} 
                subcontent={item.acf.tabela1_tabela_regionalne} 
                //scrollTo={scrollTo} 
              />
          ))}
          {query.allWordpressAcfPages.nodes.map(item => (
              <Table 
                title={item.acf.tabela2_tytul} 
                content={item.acf.tabela2_tabela_glowna} 
                subcontent={item.acf.tabela2_tabela_regionalne} 
                // scrollTo={scrollTo} 
              />
          ))}
          {query.allWordpressAcfPages.nodes.map(item => (
              <Table 
                title={item.acf.tabela3_tytul} 
                content={item.acf.tabela3_tabela_glowna} 
                subcontent={item.acf.tabela3_tabela_regionalne} 
                // scrollTo={scrollTo} 
              />
          ))}
        </Container>
      </TableSection>
      <Section 
        id={'kontakt'} 
        //ref={scrollTo}
      >
        <Container>
          <SectionHeader padding="25px 0">Kontakt z nami</SectionHeader>
          <CssGrid sm={'1fr 2fr'} padding="25px 0">
            <Contact>
              <Title3 color={styles.colorBlack}>
                Wojewódzki Szkolny Związek Sportowy w Szczecinie
              </Title3>
              <ContactList>
                <ContactItem>ul. Garncarska 5, 70 - 377 Szczecin</ContactItem>
                <ContactItem>tel. <a href={"tel:914842179"}>91 48 - 42 - 170</a></ContactItem>
                <ContactItem>tel.<a href={"tel:914847683"}>91 48 - 47 - 683</a></ContactItem>
                <ContactItem>e-mail:<a href={"mailto:wszs@szczecin.home.pl"}>wszs@szczecin.home.pl</a></ContactItem>
                <ContactItem>e-mail SKS:<a href={"mailto:sks.szczecin@wp.pl"}>sks.szczecin@wp.pl</a></ContactItem>
              </ContactList>
            </Contact>
            <Map>
              <GoogleMap />
            </Map>
          </CssGrid>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
