import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Button from "./button"
import { createMuiTheme } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { styles, FlexBox } from "./styles"
import Container from "./container"
import bgImage from "../images/bg.jpg"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import Icon from "../images/pomorze_zachodnie_logo.inline.svg"
import Icon2 from "../images/01_znak_podstawowy_kolor_biale_tlo.inline.svg"
import Image from "./image"

const Background = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .parallax-inner {
    height: 100%;
    width: 100%;
  }
`

const Row = styled.div`
  display: block;
  width: 100%;
  position: relative;

  &:nth-last-child(1) {
    margin-bottom: 0;
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }
`

const TextHeader = styled.h2`
  display: none;
  font-family: "Oswald", sans-serif;
  font-size: 4vw;
  line-height: 1.2;
  background-color: #363533;
  font-weight: 400;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 1vw;

  @media (min-width: 1200px) {
    display: inline-block;
    font-size: 46px;
    margin-bottom: 15px;
  }
`

const TextLinkOut = styled.a`
  display: inline-block;
  font-family: "Oswald", sans-serif;
  font-size: 4vw;
  line-height: 1.2;
  background-color: #363533;
  font-weight: 400;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 1vw;
  text-decoration: none;
  transform: background-color 0.3s ease;

  &:hover {
    background-color: ${styles.colorPrimary};
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const TextLink = styled(Link)`
  display: inline-block;
  font-family: "Oswald", sans-serif;
  font-size: 4vw;
  line-height: 1.2;
  background-color: #363533;
  font-weight: 400;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 1vw;
  text-decoration: none;
  transform: background-color 0.3s ease;

  &:hover {
    background-color: ${styles.colorPrimary};
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const IconsContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 125px;
  left: 0;

  @media (min-width: 768px) {
    top: auto;
    bottom: 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 120px;
  //padding-bottom: 15px;
  margin-bottom: 25px;
  margin-right: 15px;

  @media (min-width: 768px) {
    height: 100px;
    width: 150px;
    //padding-bottom: 40px;
  }

  @media (min-width: 1200px) {
    height: 100px;
    width: 200px;
    //padding-bottom: 70px;
  }

  svg {
    max-height: 100%;
    width: 100%;

    path {
      // &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      //   fill: #fff;
      // }
    }
  }
  padding: 15px;
  background-color: #fff;

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "48px",
        lineHeight: "56px",
        fontWeight: "bold",
      },
    },
  },
})

const Hero = () => (
  <MuiThemeProvider theme={theme}>
    <ParallaxProvider>
      <Wrapper>
        <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
          <Background src={bgImage} />
        </Parallax>
        <Container style={{ zIndex: "1" }}>
          <Row>
            <TextLinkOut href={"https://srs.szs.pl/"} target={"_blank"}>
              System Rejestracji Szkół
            </TextLinkOut>
          </Row>
          <Row>
            <TextLink to={"./szkolny-klub-sportowy/"}>
              Szkolny Klub SPortowy
            </TextLink>
          </Row>
          <Row>
            <TextLink to={"./#terminarze"}>Terminarze zawodów</TextLink>
          </Row>
          <Row>
            <TextHeader>Terminarze zawodów</TextHeader>
          </Row>
          <Row>
            <Button href="#kontakt">Przejdź</Button>
          </Row>
        </Container>
        <IconsContainer>
          <Container style={{ zIndex: "1" }}>
            <FlexBox align={"center"}>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              <IconWrapper style={{padding: "0"}}>
                <Icon2 />
              </IconWrapper>
              <IconWrapper>
                <Image filename="logo-70-lat" />
              </IconWrapper>
            </FlexBox>
          </Container>
        </IconsContainer>
      </Wrapper>
    </ParallaxProvider>
  </MuiThemeProvider>
)

export default Hero
