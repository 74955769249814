
import React from 'react';
import styled from "styled-components";
import { styles } from "../components/styles";

const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: ${styles.colorSecondary};
    border: 2px solid ${styles.colorBlack};
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
        z-index: 1;
    }
`;

const Name = styled.p`
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: ${styles.colorSecondary};
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap;
`;

const Marker = (props) => {
    const { color, text, id } = props;
    return (
      <Wrapper style={{ backgroundColor: color, cursor: 'pointer'}} title={text} >
        <Name>{text}</Name>
      </Wrapper>
    );
  };

  export default Marker;