import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './map-marker'

const defaultProps = {
  center: {
    lat: 53.4339853,
    lng: 14.533518,
  },
  zoom: 17,
}

const GoogleMap = () => (
  <div style={{ height: '100%', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBir8Ty3NDiX6o_mlvipr3JhTjUJFqImmo' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={53.4339853}
        lng={14.533518}
        text={'WSZS Szczecin'}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
