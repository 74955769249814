import React from "react"
import { Link } from "gatsby";
import styled from "styled-components"
import ArticleImage from '../images/article.jpg'
import { styles } from "./styles"
import { transparentize } from "polished"
import parse from 'html-react-parser';

const overlayLight = transparentize(0.4, '#000');
const overlayDark = transparentize(0.2, '#000');

const Content = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 25px;
  -webkit-transition: transform .4s ease-in-out;
  -moz-transition:  transform .4s ease-in-out;
  -ms-transition: transform .4s ease-in-out;
  -o-transition: transform .4s ease-in-out;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: ${overlayLight};
    -webkit-transition: background .4s ease-in-out;
    -moz-transition:  background .4s ease-in-out;
    -ms-transition: background .4s ease-in-out;
    -o-transition: background .4s ease-in-out;

    @media (min-width: 991px) {
      background: linear-gradient(to top, ${overlayLight} 0%, transparent 75%);
      background: -webkit-linear-gradient(bottom, ${overlayLight} 0%, transparent 75%);
    }
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    &::before {
      background: ${overlayDark};
      background: ${styles.colorSecondary};
    }

    h3 {
      opacity: 1;
    }

    h4 {
      opacity: 0;
    }
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 0;
  object-fit: cover;
`

const Date = styled.span`
  color: #fff;
  z-index: 1;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .1em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin: 0;
  background-color: ${styles.colorSecondary};
  padding: 5px;
  position: absolute;
  top: 25px;
  right: 25px;
`

const TitleSm = styled.h4`
  color: #fff;
  z-index: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin-bottom: 10px;
  -webkit-transition: opacity .4s ease-in-out;
  -moz-transition:  opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  opacity: 1;
`

const TitleBg = styled.h3`
  color: #fff;
  z-index: 1;
  font-weight: 600;
  width: 100%;
  font-size: 25px;
  line-height: 27px;
  text-align: center;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: .1em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  -webkit-transition: opacity .4s ease-in-out;
  -moz-transition:  opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  opacity: 0;
`

const Article = ({ date, title, slug, photo }) => {
  const formatedDate = formatDate({ date });
  const articlePhoto = getPhoto({photo});
  return (
    <>
      <Content to={`aktualnosci/${slug}`}>
        <Image src={articlePhoto}/>
        <TitleBg>{parse(title)}</TitleBg>
        <TitleSm>{parse(title)}</TitleSm>
        <Date>{formatedDate}</Date>
      </Content>
    </>
  )
}

function getPhoto(data) {
  if (data.photo) {
    const photo = data.photo.source_url;
    return photo;
  }
}

function formatDate(data) { 
  return data.date.slice(0, 10).split("-").reverse().join("-");
}

export default Article
