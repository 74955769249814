import React from "react"
import styled from "styled-components"
import { styles } from "./styles"

const Button = styled.a`
display: flex;
align-items: center;
border: 2px solid ${styles.colorPrimary};
color: ${styles.colorBlack};
position: relative;
padding-left: 15px;
font-family: 'Oswald', sans-serif;
font-size: 14px;
line-height: 18px;
font-style: normal;
font-weight: 900;
height: 44px;
width: 170px;
border-radius: 0;
vertical-align: top;
text-decoration: none;
text-transform: uppercase;
overflow: hidden;
-webkit-transition: background-color .4s ease-in-out, color .4s ease;
-moz-transition:  background-color .4s ease-in-out, color .4s ease;
-ms-transition: background-color .4s ease-in-out, color .4s ease;
-o-transition: background-color .4s ease-in-out, color .4s ease;
outline: none;
box-shadow: none;
margin-bottom: 0;
text-align: center;
white-space: nowrap;
touch-action: manipulation;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

span {
  display: flex;
  align-items: center;
  z-index: 1;
  
  &:after {
    content: '';
    height: 7px;
    width: 7px;
    position: absolute;
    right: 15px;
    z-index: 0;
    border-width: 0;
    border-top-width: 2px;
    border-right-width: 2px;
    border-style: solid;
    border-color: ${styles.colorPrimary};
    transform: rotate(45deg) skew(10deg, 10deg);
  }
}

&::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: ${styles.colorBlack};
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

&::after {
  content: '';
  width: 80%;
  position: absolute;
  z-index: 0;
  top: 1px;
  left: 1px;
  bottom: 1px;
  -webkit-clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 94% 0, 100% 50%, 94% 100%, 0% 100%);
  background-color: ${styles.colorPrimary};
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

&:hover {
  border-color: ${styles.colorBlack};
    
  span {
    color: ${styles.colorPrimary};
    
    &::after {
      border-color: ${styles.colorBlack};
    }
  }
  

  &::after {
    clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 6% 50%, 0 0);
  -webkit-clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 6% 50%, 0 0);
    -webkit-transform: translate3d(95%, 0, 0);
    -moz-transform: translate3d(95%, 0, 0);
    -ms-transform: translate3d(95%, 0, 0);
    -o-transform: translate3d(95%, 0, 0);
    opacity: 1;
  }
`;

const MyButton = ({ href, children }) => {
  return (
      <>
          <Button href={href}><span>{children}</span></Button>
      </>
  )
}

export default MyButton;
